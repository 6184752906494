export const useSpaceStore = defineStore("spaceStore", {
    state: () => ({
        spaces: null,
    }),
    getters: {
        getSpaces() {
            return this.spaces;
        },
    },
    actions: {
        setSpaces(info) {
            this.spaces = info
        },
    }
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useSpaceStore, import.meta.hot));
}