export const hasAccessToSpace = (space) => {
    /*
    Return true if the current user was given access to this given space, otherwise, return false;
    */
    const userInfoStore = useUserInfoStore();
    let user_info = userInfoStore.getUserInfo;

    if (! isAuthenticated()) {
        return false;
    }

    if ((space == '') && (['job.view_result'].includes(priv))) {
        return true;
    }

    if (['supremo','admin'].includes(user_info.role.toLowerCase())) {
        return true;
    }

    let userDKRoleStore = useUserDKRolesStore();
    let dkroles = userDKRoleStore.getUserDKRoles;

    let assigned_role = dkroles?.[space]?.[0];

    if (! assigned_role) {
        return false;
    } else {
        return true;
    }
}