export default function useInfiniteScrollAutocomplete(fetchMore, searchStore, element) {

    const isNearBottom = ()=> {
        return (element.scrollHeight - element.scrollTop === element.clientHeight); // Element was scrolled to its end.
    }
    
    const handleScroll = () => {

      fetchMore.value = false;
      if ((isNearBottom()) && (searchStore.getSearchPagination.has_more_results || searchStore.getCommunitySearchPagination.has_more_results)) {
        fetchMore.value = true;
      }
    };
  
    element.removeEventListener('scroll', handleScroll);
    element.addEventListener('scroll', handleScroll);

  }
  