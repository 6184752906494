<template>
  <div class="pt-2 px-4" v-if="(taskStore.getNotifications != null) && (taskStore.getNotifications.length + taskStore.getNotificationsForCurrentlySelectedSpace.length > 0)">
    <!--components/Header/NotificationBell.vue-->
    <div class="bell-area position-relative" :key="'notification_bell_' + force_update_ref.valueOf()" >
      <i ref="notificationIconRef" class="fa fa-bell-o bell-icon pt-2" @click="openNotificationUI"></i>
      <span class="dk-badge rounded-circle" v-if="(taskStore.getNotifications.length + taskStore.getNotificationsForCurrentlySelectedSpace.length ) > 0">{{ (taskStore.getNotifications.length + taskStore.getNotificationsForCurrentlySelectedSpace.length) }}</span>
      <div ref="notificationRef" :class="{'notification-ui-active': showNotification, 'd-none': ! showNotification}">
        <ul class="list-unstyled notification-list border" :class="{'d-none': taskStore.getNotifications.length == 0}">
          <li v-for="(item, i) in taskStore.getNotifications" :key="i" class="notification-row" @click="goToPage(item)">
            <span class="notification-text-dark">Task edit permission request from</span> 
            <span class="notification-text-light">{{item.first_name}}  {{item.last_name}}</span> 
            <span class="notification-text-dark">for</span> 
            <span class="notification-text-light">{{item.title}}</span>
          </li>
        </ul>

        <ul class="list-unstyled notification-list border" :class="{'d-none': taskStore.getNotificationsForCurrentlySelectedSpace.length == 0}">
          <li v-for="(item, i) in taskStore.getNotificationsForCurrentlySelectedSpace" :key="'notification_space_' + i" class="notification-row" @click="goToPage(item)">
            <span class="notification-text-light">{{item.first_name}}  {{item.last_name}}</span> 
            <span class="notification-text-dark">want to submit </span> 
            <span class="notification-text-light">{{item.title}}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
const showNotification = ref(false)
let notificationRef = ref(null);
let notificationIconRef = ref(null)
const taskStore = useTaskStore();
let space_dropdown_state = useSpaceDropdownState();
let update_notification_bell_state = useUpdateNotificationBellState();

const aYearFromNow = new Date();
aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);
const current_space_cookie = useCookie('current_space', {
  'sameSite': 'lax',
  'expires': aYearFromNow
});

if (current_space_cookie.value) {
  space_dropdown_state.value = current_space_cookie.value;
}

const route = useRoute();
let nuxtApp = useNuxtApp();

if (taskStore.getNotifications == null) {
  if ((isAuthenticated()) && (! route.path.includes('vLoginRedirect'))) {
    await taskStore.fetchNotifications();
    await taskStore.fetchNotificationsForCurrentlySelectedSpace(space_dropdown_state.value, nuxtApp);
  }
}

watch(space_dropdown_state, async (newVal, oldVal) => {
  await taskStore.fetchNotificationsForCurrentlySelectedSpace(space_dropdown_state.value);
  update_notification_bell_state.value = update_notification_bell_state.value + 1;
});

let force_update_ref = ref(0);
watch(update_notification_bell_state, async (newVal, oldVal) => {
  /*
  Here we are using dummyWait because when we approval or reject a task, there we may be doing a "fast save" without waiting for the 
  other shards to catch up, and therefore, without using dummyWait here, the query inside fetchNotificationsForCurrentlySelectedSpace
  will returns stale data.
  */
  await dummyWait(1000);
  await taskStore.fetchNotificationsForCurrentlySelectedSpace(space_dropdown_state.value);
  force_update_ref.value = force_update_ref.value + 1;
});


const goToPage = (item) => {
  showNotification.value = false
  window.location.href = `/tasks/${item.taskId}`;
}
const openNotificationUI = () => {
  if ((taskStore.getNotifications.length > 0) || (taskStore.getNotificationsForCurrentlySelectedSpace.length)) {
    showNotification.value = true
  }
}

const handleClickOutside = (event) => {
  if (notificationRef.value !== null) {
    if (!notificationRef.value.contains(event.target) && !notificationIconRef.value.contains(event.target)) {
      showNotification.value = false
    }
  }
}

onMounted(async() => {
  document.addEventListener('click', handleClickOutside)
})

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside)
})

</script>

<style scoped>
.bell-icon {
  font-size: 21px;
  cursor: pointer;
}
.dk-badge {
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 6px;
  padding-right: 6px;
  font-size: 10px;
  background-color: #ff5000;
  /* opacity: 0.7; */
  color: #fff;
  font-weight: bold;

  position: absolute;
  right: -8px;
  top: 0px;
}

/* dropdown style */

.notification-list {
  margin: 0px;
  min-width: 400px;
  max-width: 500px;
}
.notification-list li {
  padding: 5px 10px;
  display: block;
  text-align: left;
  margin-left: 0px;
  cursor: pointer;
  border-bottom: 2px solid #eee
}

.notification-list li:last-child {
  border-bottom: none
}
.notification-list li:before {
  display: none;
}
.notification-list li a {
  text-decoration: none;
  color: black;
}
.notification-ui-active{
  display: block !important;
  position: absolute;
  top: 30px;
  right: 0px;
  background-color: white;
  border: 1px solid #eee;
  z-index: 1000;
}
.notification-row:hover{
  background-color: #eee;
}
.notification-text-light{
  color: #5383ec;
  padding-right: 6px;
  font-size: 14px
}
.notification-text-dark{
  color: black;
  padding-right: 6px;
  font-size: 14px
}
</style>
