export const provisionProxy = async (org, alias) => {
    const result = await logFetchAJAX(getUrl('/provisionProxy'), {
        method: 'POST',
        body: {
          "alias": alias,
          "org": org,
          "fororg": org
        }
    });
    return result;

}