<template>
    <!--components/SignInBtn.vue-->
    <NuxtLink to="/vlogin" class="btn blue-btn" no-prefetch>{{ button_label }}</NuxtLink>
</template>

<script setup>
let button_label = 'Sign in'
</script>

<style scoped>
.blue-btn {
    padding-top: 5px;
    padding-bottom: 5px;
    align-self: center;
    margin-bottom: 5px;
    margin: 0px 5px !important;
}
</style>