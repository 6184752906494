<template>
    <!--components/CreateRunbookBtn.vue-->
    <button class="btn btn-outline-primary create-runbook-btn prevent_vertical_expansion" @click="create_runbook" :disabled="! page_mounted_state"><i class="fal fa-pen" aria-hidden="true"></i>Create Workflow</button>
    <teleport to="body">
        <ModalDialog title="" message="You need to sign in to create a runbook" v-if="show_must_sign_in_message.valueOf()" width="500px" @close="must_sign_in_message_close"/>
    </teleport>
    <teleport to="body">
        <CreateRunBookModal title="" message="Do you want to simply ask GenAI to create a runbook?" v-if="show_popup_to_ask_gen_ai.valueOf()" width="500px" @closeModal="hide_modal" :show_close="true" :title="' '"/>
    </teleport>
</template>

<script setup>
    let show_must_sign_in_message = ref(false);
    let show_popup_to_ask_gen_ai = ref(false);
    const page_mounted_state = usePageMountedState();

    const create_runbook = (event) => {
        if (process.client) {
            let isAuthenticated = localStorage.getItem("isAuthenticated");
            if ((isAuthenticated != null) && (isAuthenticated == "1")) {
                //dkNavigateTo('/task-create')
                show_popup_to_ask_gen_ai.value = true
            } else {
                show_must_sign_in_message.value = true;
            }
        }
    }

    const must_sign_in_message_close = () => {
        show_must_sign_in_message.value = !show_must_sign_in_message.value;
        const loginRedirect = useCookie('vloginRedirect', {
            'sameSite': 'lax'
        });
        loginRedirect.value = '/task-create';
        navigateTo('/vlogin');
    };

    const hide_modal = () => {
        show_popup_to_ask_gen_ai.value = !show_popup_to_ask_gen_ai.value;
    };


</script>

<style scoped>
.create-runbook-btn {
    position: relative;
    padding-left: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-right: 10px;
    margin-left: 10px;
}
.create-runbook-btn .fa-pen {
    position: absolute;
    top: 10px;
    left: 8px;
    z-index: 1;
}
.prevent_vertical_expansion {
    align-self: center;
}

.create-runbook-btn:hover {
    border: 1px solid blue;
}
</style>
