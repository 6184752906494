<template>
    <div class="container footer-bg dd-footer">
        <div class="row">
            <div class="col-4 text-left p-0">
                <img src="/vstatic/images/logo.png" class="logo">
                <p>AI created and expert curated runbooks</p>
                <div class="mt-5 dd-copy">&copy; {{ new Date().getFullYear() }} DagKnows, Inc. All rights reserved</div>
            </div>
            <div class="col-2 p-0">
                <h6>Resources</h6>
                <div class="mb-2">
                    <a href="https://www.dagknows.com/blog/" target="_blank">Blog</a>
                </div>
                <div>
                    <a href="#">Docs</a>
                </div>
            </div>
            <div class="col-2 p-0">
                <h6>Company</h6>
                <div>
                    <a href="https://www.dagknows.com/about/" target="_blank">About</a>
                </div>
            </div>
            <div class="col-2 p-0">
                <h6>Legal</h6>
                <div class="mb-2">
                    <NuxtLink to="/privacy-policy" no-prefetch>Privacy policy</NuxtLink>
                    <!-- <a href="https://www.dagknows.com/privacy-policy">Privacy policy</a> -->
                </div> 
                <div>
                    <NuxtLink to="/terms-of-use" no-prefetch>Terms of use</NuxtLink>
                    <!-- <a href="https://www.dagknows.com/terms-of-use">Terms of use</a> -->
                </div>
            </div>
            <div class="col-2 p-0 footer-icon-area">
                <div class="footer-icon">
                    <a href="https://www.youtube.com/@dagknows3120" target="_blank">
                        <i class="fa fa-youtube-play text-danger" aria-hidden="true"></i>
                    </a>
                    <a href="#" target="">
                        <i class="fa fa-github" aria-hidden="true"></i>
                    </a>

                    <a href="https://www.linkedin.com/company/dagknows-inc" target="_blank">
                        <i class="fa fa-linkedin-square" aria-hidden="true"></i>
                    </a>

                    <a href="#" target="">
                        <i class="fa fa-twitter" aria-hidden="true"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

</script>

<style scoped>
.footer-bg{
    background-color: #F3F3F3;
    padding-left: 30px !important;
    padding-right: 20px !important;
    padding-top: 50px !important;
    padding-bottom: 20px !important;
    max-width: 1228px;
    margin: 0 auto;
}

.dd-footer a{
    color: #535252;
    text-decoration: none;
}
.logo {
  width: 150px;
  margin-bottom: 10px;
}

.dd-copy{
    font-size: 13px;
}
.footer-icon-area{
    display: grid;
    height: 120px;
    align-items: end;
    text-align: right;
}
.footer-icon a{
    padding-right: 15px;
    font-size: 26px;
}
</style>